import {
  useInlineMemoizedContentGetter,
  useMemoizedContentGetter,
} from 'utils/contentful/useMemoizedContentGetter'
import Section from 'components/Section'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TitleDivider } from 'components/TitleDivider'
import { CardCarousel } from 'components/Carousels/CardCarousel'
import { ThreeProductCard } from 'components/ThreeProductCard'
import { AchieveTheme } from '@achieve/ascend'

const CARD_CAROUSEL_MINIMUM_LENGTH = 4

function ProductCards({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const { eyebrow, title, subtitle, grid, config } = useMemoizedContentGetter(content, [
    'eyebrow',
    'title',
    'subtitle',
    'grid',
    'config',
  ])

  const { jsonContent } = useInlineMemoizedContentGetter(content?.fields?.additionalConfiguration, [
    'jsonContent',
  ])

  const titleVariant = config?.jsonContent?.titleVariant || 'displayLg'
  const titleMobile = config?.jsonContent?.titleVariantMobile || 'displayMd'
  const variant = {
    title: isMobile ? titleMobile : titleVariant,
    titleFontWeight: config?.jsonContent?.titleFontWeight || 'bold',
    subtitle: isMobile ? 'bodySm' : 'displaySm',
    subtitleFontWeight: 'regular',
  }

  // Cards that re in draft mode won't have the `fields` prop
  const publishedCards = (grid?.gridItemsCollection?.items || []).filter((item) => Boolean(item))

  if (!publishedCards.length) return null

  const carouselEnabled =
    jsonContent?.mobileHorizontalScroll && isMobile
      ? true
      : publishedCards.length >= CARD_CAROUSEL_MINIMUM_LENGTH
  const CardLayoutComponent = carouselEnabled ? CardCarousel : ThreeProductCard

  const backgroundColor = jsonContent?.backgroundColor
  const eyebrowColor = jsonContent?.eyebrowColor

  const backgroundColorMap = {
    'light-blue': AchieveTheme?.ascend?.colors?.blue?.[50],
    blue: AchieveTheme?.ascend?.colors?.blue?.[25],
    white: AchieveTheme?.ascend?.colors?.neutral?.[0],
    grey: AchieveTheme?.ascend?.colors?.neutral[25],
  }

  return (
    <Section
      backgroundColor={
        backgroundColorMap[backgroundColor] ?? AchieveTheme?.ascend?.colors?.neutral?.[0]
      }
      className={'py-8 px-0 lg:py-16 lg:px-0'}
      childContainerClassName={
        carouselEnabled && 'group-data-[narrow]:max-w-full group-data-[narrow]:p-0 lg:p-0'
      }
      data-testid="product-card-section"
    >
      <TitleDivider
        eyebrow={eyebrow}
        eyebrowBlack={eyebrowColor ?? eyebrow?.fontColor === 'Black'}
        title={title}
        subtitle={subtitle}
        isMobile={isMobile}
        data-testid="product-card-section-title-area"
        variants={variant}
      />
      {grid && (
        <CardLayoutComponent
          content={{ gridItems: publishedCards }}
          isMobile={isMobile}
          productCardVariant={jsonContent?.variant || 'ProductCard'}
        />
      )}
    </Section>
  )
}
export { ProductCards }
export default ProductCards
